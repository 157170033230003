import type { Lot, Program, Slice } from "~/types/drupal_jsonapi";

type ProgramWrapperParams = {
  program: Program;
  slices: Slice[];
  lots: Lot[];
};

export type ProgramWrapper = ReturnType<typeof createProgramWrapper>;

/** Wraps a program's raw data into a decorator */
export const createProgramWrapper = (params: ProgramWrapperParams) => {
  return {
    program: params.program,
    slices: params.slices,
    lots: params.lots,
  };
};
