"use server";
import { consola } from "consola";
import { CacheGet, CacheSet } from "~/lib/cache";
import { isProd } from "~/lib/env";
import type {
  KeyThatShouldBeNumber,
  RelationshipField,
} from "~/types/drupal_jsonapi";

import { getRequestEvent } from "solid-js/web";

const debugFetcher = import.meta.env.VITE_COG_DEBUG_FETCHER == 1;
const cacheEnabled = import.meta.env.VITE_COG_CACHE_ENABLED == 1;
const cacheDebug = import.meta.env.VITE_COG_CACHE_DEBUG == 1;

export type FetchApiOptions = {
  withMeta?: boolean;
  method?: "GET" | "POST" | "PUT" | "DELETE";
  cookie?: string;
};

export async function fetchAPI(path: string, opts?: FetchApiOptions) {
  return fetchAPIInternal(path, opts);
}

type Options = {
  method?: FetchApiOptions["method"];
  bypassCache?: boolean;
};

export const getDataAtPath = async <T>(
  path: string,
  options?: Options,
): Promise<T> => {
  const event = getRequestEvent();
  const hasDrupalAuthCookie =
    event!.request.headers.has("Cookie") &&
    event!.request.headers.get("Cookie")!.includes("DrupalSolidAuth=loggedIn");

  if (cacheEnabled && !options?.bypassCache && !hasDrupalAuthCookie) {
    const data = (await CacheGet(path)) as T;
    if (data) {
      cacheDebug && consola.info(`Cache hit : ${short(path)}`);
      return data;
    }
  }

  cacheDebug && consola.info(`Cache miss: ${short(path)}`);
  return fetchAPI(path, options);
};

export async function fetchAPIInternal(path: string, opts?: FetchApiOptions) {
  const event = getRequestEvent();
  const hasDrupalAuthCookie =
    event!.request.headers.has("Cookie") &&
    event!.request.headers.get("Cookie")!.includes("DrupalSolidAuth=loggedIn");

  const headers = new Headers();
  headers.append("User-Agent", "chrome");

  if (import.meta.env.VITE_COG_BACKEND_AUTH_LOGIN.length > 0) {
    headers.append(
      "Authorization",
      "Basic " +
        btoa(
          import.meta.env.VITE_COG_BACKEND_AUTH_LOGIN +
            ":" +
            import.meta.env.VITE_COG_BACKEND_AUTH_PASSWORD,
        ),
    );
  }

  let url = `${import.meta.env.VITE_COG_BACKEND_BASE_URL}/${path}/`;

  if (url.indexOf("?") > -1) {
    // remove trailing slash
    url = url.replace(/\/$/, "");
  }

  try {
    headers.append("Cookie", event!.request.headers.get("Cookie")!);

    debugFetcher && consola.info(`Fetching ${url}`);
    debugFetcher && consola.info("Headers", headers);

    const response = await fetch(url, { headers, method: opts?.method });

    if ("error" in response) {
      return response;
    }

    if (response.status !== 200) {
      !isProd() &&
        consola.warn(
          `Server responded with status ${response.status} for ${url}`,
        );
      return {
        code: response.status,
        error: `Server responded with status ${response.status}`,
      };
    }

    const json = await response.json().catch((error: string): void => {
      throw new Error(`Was Fetching ${url}, got ${error}`);
    });

    if (!("data" in json)) {
      !isProd() && consola.error(`JSON response does not contain data`, json);
      return {
        code: 500,
        error: `JSON response does not contain data`,
      };
    }

    cacheDebug &&
      consola.log(
        `Response headers ${response.headers.get("X-Drupal-Cache-Tags")}`,
      );

    // Fix empty fields
    let data = fixEmptyFields(json.data);
    if (path.includes("lots") && Array.isArray(data)) {
      // We have a special case for lots, we need to cast some fields to numbers
      // and fix empty fields for each lot.
      data = castNumberValuesForLots(data.map(fixEmptyFields));
    }

    if (cacheEnabled && !hasDrupalAuthCookie) {
      await CacheSet(
        path,
        data,
        json.meta.cache.tags,
        json.meta.cache["max-age"],
      );
    }

    return opts?.withMeta ? json : json.data;
  } catch (error) {
    return { error };
  }
}

const short = (str: string, maxLen: number = 80): string => {
  if (str.length <= maxLen) {
    return str;
  }
  return str.slice(0, maxLen - 3) + "…";
};

/**
 * Recursively fix empty fields in a JSON object
 * @param obj T
 * @returns T
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fixEmptyFields<T extends Record<string, any>>(obj: T) {
  const isPotentiallyEmptyField = function (key: keyof T, obj: T) {
    return (
      key.toString().startsWith("field_") &&
      typeof obj[key] === "object" &&
      !Array.isArray(obj[key]) &&
      obj[key]
    );
  };

  const isParentIdRef = function (key: keyof T, obj: T) {
    return key === "parent_id" && typeof obj[key] === "object";
  };

  const isNull = function (key: keyof T, obj: T) {
    return "data" in obj[key] && obj[key]["data"] === null;
  };

  const isEmptyArray = function (key: keyof T, obj: T) {
    return (
      "data" in obj[key] &&
      Array.isArray(obj[key]["data"]) &&
      obj[key]["data"].length === 0
    );
  };

  for (const key in obj) {
    if (isPotentiallyEmptyField(key, obj) || isParentIdRef(key, obj)) {
      if (isNull(key, obj)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        obj[key] = null as any;
      } else if (isEmptyArray(key, obj)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        obj[key] = [] as any;
      } else if (
        isEntityRelationField(obj[key] as Partial<RelationshipField>)
      ) {
        // Entity reference, check if it has a data field
        obj[key] = fixEmptyFields(obj[key]);
      }
    } else if (Array.isArray(obj[key])) {
      // If the field is an array, we need to iterate over its properties
      obj[key] = obj[key].map(fixEmptyFields);
    }
  }

  return obj;
}

/**
 * Returns true if the field is a Drupal JSON:API relationship field
 * @param field Partial<RelationshipField>
 * @returns boolean
 */
export function isEntityRelationField(field: Partial<RelationshipField>) {
  return field && field.type && field.id && field.meta;
}

export function castNumberValuesForLots<T extends Record<string, unknown>[]>(
  obj: T,
): T {
  const keysToCast: Set<KeyThatShouldBeNumber> = new Set([
    "price_vat_ex",
    "price_vat_inc",
    "price_vat_inc_reduced",
    "price_vat_inc_brs",
    "price_vat_inc_mastered",
    "price_furniture_vat_ex",
    "return_rate_vat_ex",
    "return_rate_vat_inc",
    "return_rate_pinel",
    "fees_vat_inc",
    "vat_rate",
    "rooms",
    "surface",
    "garden",
    "balcony",
    "loggia",
    "terrace",
  ]);

  const cast = (obj: Record<string, unknown>) => {
    Object.keys(obj).forEach((key) => {
      if (
        keysToCast.has(key as unknown as KeyThatShouldBeNumber) &&
        obj[key] != null
      ) {
        obj[key] = Number(obj[key]);
      }
    });
    return obj;
  };

  return obj.map(cast) as T;
}
